import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { cloneDeep, indexOf, isEqual, last, throttle } from 'lodash';

import i18n from 'src/i18n/i18n';
import theme, { Themes } from 'src/theme';
import { getDeviceDetect, getFixedGmt } from 'src/utils';

import {
  CircleMode,
  ICalculation,
  IMap,
  IAspect,
  ObjectType,
  getDirections,
  getGMT,
  getLocalTime,
  isRelocation,
  IFixedStar,
  houseAspects,
  housesPower,
  isPrognostics,
  isSynastry,
  localTime,
  planetsPower,
  profileMode,
  RelocationsMode,
  getSign, degToString, signs, PrognosticsMode, directionShift,
  Button, hide, show,
  IBaseData, IFormData, IMapDescription, IShortFormData, IWidgetsItem, isValidISOString, nowISOString,
  ISynastryPartnerData,
  IAstroSettings
} from 'src/libs';

import api from 'src/api';
import astro, { CircleModes } from 'src/astro';
import { goToMap, history, routes } from 'src/router';
import store from 'src/store';
import StarsData from 'src/pages/Settings/sections/stars.json';
import HorarStarsData from 'src/guide/fixedStars';

import { useAsyncMemo } from 'use-async-memo';

import { searchParams } from 'src/utils';
import SideBar from 'src/pages/SideBar';
import Footer from './Footer';
import Header from './Header';
import Widgets from './Widgets';
import { IStrongs, WidgetTypes } from './Widgets/Widget';
import { TimeControlType } from './Footer/TimeControl';
import Maps, { hideMapInfoPopup } from './Maps';
import { IPrompt } from './Widgets/Prompts/types';
import WelcomeInstruments from 'src/components/WelcomeInstruments';
// import AccessDenied from './AccessDenied';

import TariffsPopup from './TariffsPopup';
import { ReactComponent as NoChartIcon } from 'src/assets/illustrations/no-chart.svg';
import { MapPreloader } from './Preloaders';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { MinorAspectsModes } from "src/components/Circle/Aspects";
import { Dispositors } from 'src/helpers/Dispositors';
import { acccesOnlyForRu } from 'src/helpers/permissions';

import dayjs from 'dayjs';
import { useTranslation } from 'src/i18n/useTranslation';
import NotFound from '../NotFound';
import { compatibilityToPartner, getModeNumber, getSynastryModes, isCompatibility, isPartner, isSynNatal } from './utils';
import { observer } from 'mobx-react';
import { AspectsPatterns, IAspectPattern } from "../../libs";

import { switcherHook, useKeyHook } from 'src/hooks';
import { IHighlightsData } from 'src/components/Circle';
interface IInitialState {
  widgetMode: CircleMode;
  currentMode: CircleMode;
  modes: CircleMode[];
  maps: IMap[] | [];
  natalMap: IMap | null;
  partnersMaps: { [key: string]: IMap } | null;
  strongs: IStrongs;
  synastryModes: CircleMode[];
  prognosticsModes: CircleMode[];
  relocationModes: CircleMode[];
  widgets: IWidgetsItem[];
  form: IFormData | null;
  natal: IMap | null;
  highlights: IHighlightsData;
  description: IMapDescription | null;
  accessError: string;
  descriptionSoul: number | null;
  prompt: IPrompt | null;
  pinnedAspects: number[];
  skipUpdate: boolean;
  minorAspectsMode: MinorAspectsModes;
}
const throttleUpdateForm = throttle(async function(f: IFormData) {
  return api.updateForm(f);
}, 1500, {
  leading: false
});

export interface IHighlight {
  map: CircleMode;
  type: 'aspect' | 'object' | 'object_ext' | 'house' | 'house_ext' | 'sign';
  id: number;
}

export interface IFixedStarExt extends IFixedStar {
  constellation: string,
  constellationRu: string;
  sign: string;
  signRu: string;
  isHorar?: boolean;
}

// @ts-ignore
import InstrumentsContext, { IInstrumentContext, defaultContext } from './context';


const { isTablet } = getDeviceDetect();

let _oldPageTitle: string | null = null;
let _oldPageTheme: Themes;

export const INNER_CIRCLE_PROPORTION = 1.25;

const initialStrongs = {
  planets: [],
  houses: {
    houses: [],
    planets: [],
    power: []
  },
  aspects: []
};

async function calcSoul(dt: string, gmt: number | null, lat: number, lon: number) {
  const data = await astro.natal(getLocalTime(dt, gmt, lat, lon), lat, lon);

  const disp = Dispositors(data.objects);

  return {
    ...data,
    ...disp
  };
}

function calcNatalAspectsWithFloatingOrbiseCorrector(aspects: IAspect[], natal: ICalculation): IAspect[] {
  const floatingOrbise = (store.activeAstroProfile && store.activeAstroProfile.closureConfig) ?? 0;

  if (!floatingOrbise) {
      return aspects;
  }

  let newAspects = astro.natalAspects(natal.objects, undefined, natal.houses, undefined, { floatingOrbise });
  const newConfigurations = new AspectsPatterns(newAspects.filter(item => item.obj1 < 10 && item.obj2 < 10)).findPatterns() as IAspectPattern[];

  const isSameAspects = (aspect1: IAspect, aspect2: IAspect) => (aspect1.obj1 === aspect2.obj1 && aspect1.obj2 === aspect2.obj2) || (aspect1.obj1 === aspect2.obj2 && aspect1.obj2 === aspect2.obj1);
  const aspectInConfigurations = ((aspect: IAspect, configurations: IAspectPattern[]) => configurations.find((configuration: IAspectPattern) => configuration.aspects.find((confAspect: IAspect) => isSameAspects(confAspect, aspect))));
  const aspectInArrayOfAspects = ((aspect: IAspect, aspects: IAspect[]) => aspects.find((confAspect: IAspect) => isSameAspects(confAspect, aspect)));

  newAspects = newAspects.map(a => {
    let aspect = a;
    // Если аспект не входит в конфигурации, но при этом уже был, то снимаем с него орбисы и всё всё всё, дабы он был прежним
    if (aspectInArrayOfAspects(a, aspects) && !aspectInConfigurations(a, newConfigurations)) {
      aspect = aspects.find((aspect: IAspect) => isSameAspects(a, aspect)) as IAspect;
    }

    return aspect;
  });

  aspects = newAspects.filter(aspect => aspectInConfigurations(aspect, newConfigurations) || aspectInArrayOfAspects(aspect, aspects));

  // Ставим индексы и id на место
  aspects = aspects.map((item, i) => {
    item.id = i;
    return item;
  });

  return aspects;
}

export async function calculation(mode: CircleMode, form: IFormData, mapIndex?: number, activeProfile?: IAstroSettings) {
  // console.log(`* --- calculation for mode: ${mode}`, (astro.settings.maps as any)[mode].orbiseCorrector)
  const isNatal = ['natal', 'syn_natal', 'relocation_natal'].includes(mode)

  const profile = activeProfile || store.activeAstroProfile;

  if (profile) {
    const { maps } = astro.settings;


    // FIXME:
    // @ts-ignore
    astro.settings = {
      ...cloneDeep(profile),
      housesSystem: form.housesSystem
    };

    if (maps) {
      Object.keys(maps).forEach(k => {
        (astro.settings.maps as any)[k].orbiseCorrector = (maps as any)[k].orbiseCorrector;
      });
    }
  }

  const ErisObjectNatal = await astro.object(localTime(form.natal), 136199 + 10000);

  const natal: ICalculation = ['soul'].includes(mode)
    ? {
        houses: [],
        objects: []
      }
    : await astro.natal(
        localTime(form.natal),
        form.natal.place.lat,
        form.natal.place.lon,
        form.cosmogram
      );

  if (isNatal) {
    let aspects = astro.natalAspects(natal.objects, undefined, natal.houses, undefined, { floatingOrbise: 0 });
    aspects = calcNatalAspectsWithFloatingOrbiseCorrector(aspects, natal);


    if (mode === 'relocation_natal') {
      aspects.push(...houseAspects(natal.objects, natal.houses, aspects.length, store.activeAstroProfile?.id === 1 ? 5 : 4).map(a => {
        const z = a.obj1;
        a.obj1 = a.obj2;
        a.obj2 = z;
        return a;
      }));
    }

    return {
      ...natal,
      objects: [...natal.objects, ErisObjectNatal],
      aspects,
      objectsExt: [],
      housesExt: []
    };
  }


  if (mode === 'horar') {
      if (!form.horar) throw new Error('Empty horar data');

      const horar = await astro.horar(
        localTime(form.horar),
        form.horar.place.lat,
        form.horar.place.lon
      );

      const isRounded = false;
      const { showAspects } = store.settings.profile.ui.instruments.cardSettingsForms.horar;
      const aspects = showAspects ? astro.natalAspects(horar.objects, undefined, undefined, isRounded) : [];

      const ErisObjectHorar = await astro.object(localTime(form.horar), 136199 + 10000);

      return {
        ...horar,
        objects: [...horar.objects, ErisObjectHorar],
        aspects: aspects,
        objectsExt: [],
        housesExt: []
      };
    }

  if (mode === 'soul') {
      const soul = await calcSoul(
        form.natal.dt,
        form.natal.gmt,
        form.natal.place.lat,
        form.natal.place.lon
      );

      return {
        ...soul,
        aspects: [],
        soulObjects: soul.objectsExt,
        housesExt: []
      };
    }

  if (isSynastry(mode)) {
      const partners = form.partners?.length
        ? form.partners
        : form.synastry
            ? [form.synastry]
            : null;

      if (!partners) { throw new Error('Empty synastry data') };

      const partnerIndex = +mode.slice(-1) - 1 ?? 0;
      const partner = partners[partnerIndex];
      const partnerExt = store.instruments.mapIndicatorCurrent.partnerExt[mode] ?? true;
      const partnerNatal = store.instruments.mapIndicatorCurrent.partnerNatal[mode] ?? true

      if (!partner) { throw new Error('Empty synastry data') };

      const ErisObjectPartner = await astro.object(localTime(partner), 136199 + 10000);

      if(isCompatibility(mode)) {
        const ext = await astro.natal(
          localTime(partner),
          partner.place.lat,
          partner.place.lon,
          false
        );

        if (store.instruments.mapIndicatorCurrent.compatibilityReversed) {
          return {
            ...ext,
            objects: [...ext.objects, ErisObjectPartner],
            aspects: astro.synastryAspects(ext.objects, natal.objects),
            objectsExt: [...natal.objects, ErisObjectNatal],
            housesExt: natal.houses,
            name: 'natal'
          };
        } else {
          return {
            ...natal,
            objects: [...natal.objects, ErisObjectNatal],
            aspects: astro.synastryAspects(natal.objects, ext.objects),
            objectsExt: [...ext.objects, ErisObjectPartner],
            housesExt: ext.houses,
            name: partner.name
          };
        }
      } else {
        const partnerNat = await astro.natal(
          localTime(partner),
          partner.place.lat,
          partner.place.lon,
          false
        );
        if (partnerExt && partnerNatal) {
          return {
            ...natal,
            objects: [...natal.objects, ErisObjectNatal],
            aspects: astro.synastryAspects(natal.objects, partnerNat.objects),
            objectsExt: [...partnerNat.objects, ErisObjectPartner],
            housesExt: partnerNat.houses,
            name: partner.name
          };
        } else if (partnerExt && !partnerNatal) {
          return {
            ...partnerNat,
            objects: [...partnerNat.objects, ErisObjectPartner],
            aspects: astro.natalAspects(partnerNat.objects, undefined, partnerNat.houses),
            objectsExt: [],
            housesExt: [],
            name: partner.name
          };
        } else {
          return {
            ...natal,
            objects: [...natal.objects, ErisObjectNatal],
            aspects: astro.natalAspects(natal.objects),
            objectsExt: [],
            housesExt: [],
            name: partner.name
          };
        }
      }
    }

  if (isRelocation(mode)) {
    const d = form.relocation?.[mode as RelocationsMode] ?? form.natal;

    const relocation = await astro.natal(
      localTime(d),
      d.place.lat,
      d.place.lon,
    );

    const natal = await astro.natal(
      localTime(form.natal),
      form.natal.place.lat,
      form.natal.place.lon,
    );

    let aspects = astro.natalAspects(relocation.objects, undefined, undefined, undefined, { floatingOrbise: 0 });
    aspects = calcNatalAspectsWithFloatingOrbiseCorrector(aspects, natal);

    const ErisObjectRelocation = await astro.object(localTime(d), 136199 + 10000);

    return {
      ...natal,
      objects: [...natal.objects, ErisObjectNatal],
      aspects: [
        ...aspects,
        ...houseAspects(relocation.objects, relocation.houses, aspects.length, store.activeAstroProfile?.id === 1 ? 5 : 4).map(a => {
          const z = a.obj1;
          a.obj1 = a.obj2;
          a.obj2 = z;
          return a;
        }),
      ],
      objectsExt: [...relocation.objects, ErisObjectRelocation],
      housesExt: relocation.houses
    };
  }

  if (!form.prognostics) throw new Error('Empty prognostics data');

  let { dt: progDt, gmt, place: { lat, lon } } = form.prognostics as IBaseData;
  progDt = getLocalTime(progDt, gmt, lat, lon);

  const ext: ICalculation = await (async () => {

    switch (mode) {
      case 'transits': return await astro.natal(progDt, lat, lon, false);
      case 'directions': return getDirections(natal, localTime(form.natal), progDt);
      case 'solars': return await astro.solars(natal.objects[ObjectType.Sun].lon, progDt, lat, lon);
      case 'prog_prog':
      case 'prog_natal': return await astro.progressions(localTime(form.natal), progDt, lat, lon);
      default: return { houses: [], objects: [] }
    }
  })();

  // @ts-ignore
  let forErisDateTime: string = mode === 'transits'
    ? progDt
    : mode === 'directions'
      ? localTime(form.natal)
      // @ts-ignore
      : ext.dt;

  let ErisObjectPrognostics = await astro.object(forErisDateTime, 136199 + 10000);

  if (mode === 'directions') {
    // @ts-ignore
    ErisObjectPrognostics = directionShift(ErisObjectPrognostics, ext.shift);
  }

  const [ prognosticsExt, prognosticsNatal ] = [
    (store.instruments.mapIndicatorCurrent.prognosticsExt as any)[mode as PrognosticsMode],
    (store.instruments.mapIndicatorCurrent.prognosticsNatal as any)[mode as PrognosticsMode]
  ];

  const res = (prognosticsExt && prognosticsNatal)
    ? {
      ...natal,
      objects: [...natal.objects, ErisObjectNatal],
      aspects: astro.prognosticsAspects(
        mode,
        natal.objects,
        natal.houses,
        ext.objects,
        ext.houses,
        false // forDoubleMapWithOutNatal
      ),
      objectsExt: [...ext.objects, ErisObjectPrognostics],
      housesExt: ext.houses
    }
    : (prognosticsExt && !prognosticsNatal)
      ? {
        ...ext,
        objects: [...ext.objects, ErisObjectPrognostics],
        aspects: astro.prognosticsAspects(
          mode,
          natal.objects,
          natal.houses,
          ext.objects,
          ext.houses,
          Boolean('forDoubleMapWithOutNatal')
        ),
        objectsExt: [],
        housesExt: []
      }
      : {
        ...natal,
        objects: [...natal.objects, ErisObjectNatal],
        aspects: astro.natalAspects(natal.objects),
        objectsExt: [],
        housesExt: []
      }
  ;

  return (
    res
  );

}


function getMode(mode: CircleMode, modes: CircleMode[]) {
  return (modes.includes(mode) ? mode : modes[0]) || 'natal';
}

let krutilker = 0;

export default observer(function Instruments() {
  const sp = searchParams();
  const modes = (sp.get('modes')?.split(',') || []) as CircleMode[];
  const formId = parseInt(sp.get('id') || '-1', 10);
  const isShareForm = sp.has('share');
  const t = useTranslation();
  const { astro: astroStore } = store;

  const initialState = {
    widgetMode: getMode('natal', modes),
    currentMode: getMode('natal', modes),
    modes,
    maps: [],
    natalMap: null,
    partnersMaps: null,
    strongs: initialStrongs,
    synastryModes: ['syn_natal', 'partner1', 'compatibility1'] as CircleMode[],
    prognosticsModes: ['directions', 'solars', 'transits', 'prog_natal', /*'prog_prog'*/] as CircleMode[],
    relocationModes: ['relocation_natal'] as CircleMode[],
    widgets: [
      {
        order: 0,
        id: 'aspects-table',
        isActive: false,
        canUseFree: true
      },
      {
        order: 1,
        id: 'coordinates-of-planets-and-houses',
        isActive: false,
        canUseFree: false
      },
      {
        order: 2,
        id: 'strong-objects',
        isActive: false,
        canUseFree: false
      },
      {
        order: 3,
        id: 'house-formulas',
        isActive: false,
        canUseFree: false
      },
      {
        order: 4,
        id: 'analyze',
        isActive: false,
        canUseFree: false
      },
            {
        order: 5,
        id: 'dispositors',
        isActive: false,
        canUseFree: false
      },
      {
        order: 6,
        id: 'orbise-corrector',
        isActive: false,
        canUseFree: false
      },
      {
        order: 7,
        id: 'rectification',
        isActive: true,
        canUseFree: true
      },
      {
        order: 8,
        id: 'devplan',
        isActive: true,
        canUseFree: true
      },
      {
        order: 9,
        id: 'autoprediction',
        isActive: true,
        canUseFree: true
      },
      {
        order: 10,
        id: 'interpretation',
        isActive: false,
        canUseFree: false
      },
      {
        order: 11,
        id: 'horar-events',
        isActive: false,
        canUseFree: false
      },
      {
        order: 12,
        id: 'formula-strength',
        isActive: false,
        canUseFree: false
      },
      {
        order: 13,
        id: 'ephemeris-formulas',
        isActive: false,
        canUseFree: false
      },
      {
        order: 14,
        id: 'formula-years',
        isActive: false,
        canUseFree: false
      },
      {
        order: 15,
        id: 'horar-speed',
        isActive: false,
        canUseFree: false
      },
      {
        order: 16,
        id: 'horar-essentials',
        isActive: false,
        canUseFree: false
      },
      {
        order: 17,
        id: 'formula-soul',
        isActive: false,
        canUseFree: false
      },
      {
        order: 18,
        id: 'interpretation-soul',
        isActive: false,
        canUseFree: false
      },
      {
        order: 19,
        id: 'horar-analysis',
        isActive: false,
        canUseFree: false
      },
      {
        order: 20,
        id: 'horar-light',
        isActive: false,
        canUseFree: false
      }
    ],
    form: null,
    natal: null,
    highlights: {
      items: [],
      aspects: []
    },
    description: null,
    descriptionSoul: null,
    prompt: null,
    pinnedAspects: [],
    skipUpdate: true,
    accessError: '',
    access: {
      type: 'private',
      showPersonalData: true
    },
    minorAspectsMode: MinorAspectsModes.School
  };

  const [pageContext, setPageContext] = React.useState<IInstrumentContext>(defaultContext());
  const [state, setState] = React.useState<IInitialState>(initialState);
  const [modeDateTime, setModeDiteTime] = React.useState<string>('');
  const [showEdit, setShowEdit] = React.useState<string | null>(null);
  const [usedWithAccessLimitWidgets, setUsedWithAccessLimitWidgets] = React.useState<string[]>(['aspects-table', 'devplan', 'autoprediction']);
  const [scale, setScale] = React.useState(1);
  const [minSide, setMinSide] = React.useState(0);

  const [showNotFound, setShowNotFound] = useState(false);
  const [keyForTransform, setKeyForTransform] = useState<number>(window.orientation);
  const [auxModes, setAuxModes] = useState<CircleMode[]>([]);

  const wsRef = useRef<HTMLDivElement>(null);

  window.addEventListener('orientationchange', () => {isTablet && setKeyForTransform(window.orientation)})
  const showWelcomeInstruments = switcherHook(store.settings.user.auth.showWelcomeInstruments);


  const { isLimitedAccess, isArchiveTariff, isBaseTariff, isTrialAccess, isExtendedTariff, isUnlimitedTariff } = store.settings.user;

  pageContext.subscribe(() => {
    setPageContext((ctx: IInstrumentContext) => ({...ctx, showTariffsPopup: true}))
  })


  useKeyHook('Escape', {
    onKeyDown: () => {
      setShowEdit(null);
    }
  }, []);

  React.useEffect(() => {
    if (isLimitedAccess) return;
    const onRectSelected = () => getFormData();
    window.addEventListener('chronosRectSelected', onRectSelected);
    window.addEventListener('beforeprint', changePrintSettings);
    window.addEventListener('afterprint', restorePrintSettings);

    countMapScale(wsRef);

    return () => {
      window.removeEventListener('chronosRectSelected', onRectSelected);
      window.removeEventListener('beforeprint', changePrintSettings);
      window.removeEventListener('afterprint', restorePrintSettings);
      hide('invalid_date');
    };
  }, []);

  useKeyHook([
    'KeyC',
    'KeyT',
    'KeyM',
    'KeyN',
    'KeyS',
    'KeyP',
    'KeyH',
    'KeyR',
    'AltLeft+KeyW',
    'AltLeft+Digit1',
    'AltLeft+Digit2',
    'AltLeft+Digit3',
    'AltLeft+Digit4',
    'AltLeft+Digit5',
    'AltLeft+Digit6',
    'AltLeft+Digit7',
    'AltLeft+Digit8',
    'AltLeft+Digit9',
    'AltLeft+Digit0',
    'ControlLeft+KeyA',
    'ControlLeft+KeyK'
  ], {
    delay: 200,
    onKeyDown: (key, isDelayed) => {
      if (isLimitedAccess) return;
      if (!state.natal && key == 'KeyN' && isDelayed) onHold('natal', true);
      if (key === 'KeyT') history.push('/instruments');
    },
    onKeyUp: (key, isDelayed) => {
      if (isLimitedAccess) return;
      if (key === 'KeyN') {
        if (!isDelayed) {
          onHold('natal', false).then(() => {
            onModeChanged(['natal']);
          });
        } else {
          onHold('natal', false);
        }
      }
      if (key === 'KeyS') {
        if (!!state.form?.synastry || state.form?.partners?.length) {
          onModeChanged(['compatibility1']);
        } else {
          setShowEdit('synastry');
        }
      };
      if (key === 'KeyP') {
        if (!!state.form?.prognostics) {
          onModeChanged(['directions']);
        } else {
          setShowEdit('prognostics');
        }
      }
      if (key === 'KeyH') {
        if (state.form?.access.permissions.includes('PRO')) {
          if (!!state.form?.horar) {
            onModeChanged(['horar']);
          } else {
            setShowEdit('horar');
          }
        }
      }
      if (key === 'KeyC') changeCosmodram();
      if (key === 'KeyM') changeAspectsMode();
      if (key === 'AltLeft+KeyW') onShowAllWidgets();
      if (key === 'AltLeft+Digit1') showWidget('aspects-table');
      if (key === 'AltLeft+Digit2') showWidget('coordinates-of-planets-and-houses');
      if (key === 'AltLeft+Digit3') showWidget('orbise-corrector');
      if (key === 'AltLeft+Digit4') showWidget('strong-objects');
      if (key === 'AltLeft+Digit5') showWidget('house-formulas');
      if (key === 'AltLeft+Digit6') showWidget('analyze');
      if (key === 'AltLeft+Digit7') showWidget('devplan');
      if (key === 'AltLeft+Digit8') showWidget('autoprediction');
      if (key === 'AltLeft+Digit9') showWidget('interpretation');
      if (key === 'AltLeft+Digit0') showWidget('rectification');
      if (key === 'ControlLeft+KeyA') {
        onModeChanged(
          isSynastry(modes) ? state.synastryModes : (isRelocation(modes) ? state.relocationModes : state.prognosticsModes),
          state.synastryModes,
          state.prognosticsModes,
          state.relocationModes
        );
      }
    }
  }, [state]);


  useEffect(() => {
    isTablet && setKeyForTransform(keyForTransform + 1)
  }, [state.widgets])

  useEffect(() => {
    const asyncFn = async () => {
      if (store.shareId !== null && formId !== store.shareId && store.settings.user.auth.id != -1) {
        store.shareId = null;
        await store.initSettings(store.settings.user.auth.id, t);
      }

      // if (!store.activeAstroProfile) {
      //   await store.initSettings(userId, t);
      // }
      
      // setSettingsReady(true);

      hide('invalid_date');
      
      if (store.activeAstroProfile) {
        // FIXME:
        // @ts-ignore
        astro.settings = store.activeAstroProfile;
      }
      getFormData();
    };

    asyncFn();
  }, [formId]);

  useEffect(() => {
    async function calculateMaps() {
      if (state.maps.length > 0) {
        const res = await calculate(state.form!, state.modes);

        // FIXME:
        // @ts-ignore
        setState(state => ({
          ...state,
          ...res,
          minorAspectsMode: MinorAspectsModes.School
        }));
      }
    }
    calculateMaps();
  }, [
    state.modes,
    store.instruments.mapIndicatorCurrent.compatibilityReversed,
    store.instruments.mapIndicatorCurrent.prognosticsExt,
    store.instruments.mapIndicatorCurrent.prognosticsNatal,
    store.instruments.mapIndicatorCurrent.partnerExt,
    store.instruments.mapIndicatorCurrent.partnerNatal
  ]);

  useEffect(() => {
    if (!state.form) return;

    const { isTemporary } = state.form;

    if (isTemporary) {
      document.title = t.t(routes.Instruments.title.tmpMap);
    } else {
      document.title = `${state.form.name}, ${dayjs.utc(state.form.natal.dt).format('DD.MM.YYYY')}`;
    }

    if (!state.skipUpdate && state.form.access.isPersonal) {

      throttleUpdateForm({
        ...state.form,
        settings: {
          widgets: state.widgets,
          modes: {
            prognostics: state.prognosticsModes,
            synastry: state.synastryModes,
            relocation: state.relocationModes
          },
          dfltModes: state.modes,
          dfltPrognostics: [],
          dfltSynastry: [],
          dfltRelocation: []
        }
      })?.then(({ form }: {form: IShortFormData}) => {
        store.dashboard.updateModifiedCard(form);
      });

    }

    updateAuxModes(state.form)

    setState(state => ({
      ...state,
      skipUpdate: false
    }));
  }, [state.form, state.modes, state.widgets, state.prognosticsModes, state.synastryModes, state.relocationModes, state.partnersMaps, state.form?.partners]);

  const showWidget = React.useCallback((id: string) => {
    //@ts-ignore
    setState(state => ({
      ...state,
      widgets: state.widgets.map(w => w.id === id ? {
        ...w,
        isActive: !!w.isActive ? false : new Date().toISOString(),
      } : w),
      description: null,
      descriptionSoul: null,
      prompt: null
    }));
  }, [state]);

  const onShowAllWidgets = React.useCallback(() => {
    const checkLengthActiveWidgets = state.widgets.filter(x => x.isActive).length;

    setState(state => ({
      ...state,
      widgets: changedViewWidget(state.widgets, checkLengthActiveWidgets === 0 || checkLengthActiveWidgets > 4),
      description: null
    }));
  }, [state]);

  const onChanged = React.useCallback((key: string, value: any) => {
    const onChangedGen = () => {
      if (!state.form) return;
      
      setState(state => ({
        ...state,
        form: {
          ...state.form!,
          gen: {
            ...state.form!.gen,
            [key]: value
          }
        }
      }));
    }

    if (key === 'aspects-table-mode') {
      setState(state => ({
        ...state,
        widgetMode: value,
        pinnedAspects: []
      }));
    } else if (key === 'current-mode-scroll') {
      setState(state => ({
        ...state,
        widgetMode: value,
        currentMode: value
      }));
    } else if (key === 'orbise-corrector') {
      const { value: orbiseValue, mapIndex } = value;
      const mode = profileMode(state.maps[mapIndex ?? 0].mode)
      ; (astro.settings.maps as any)[mode].orbiseCorrector = orbiseValue;

      (async () => {
        const res = await calculate(state.form!, state.modes);

        // FIXME:
        // @ts-ignore
        setState(state => ({
          ...state,
          ...res
        }));
      })();


    } else if (key === 'highlights') {
      setState(state => ({
        ...state,
        highlights: value
      }));
    } else if (key === 'pinned-aspects') {
      setState(state => ({
        ...state,
        pinnedAspects: value
      }));
    } else if (key === 'description') {
      if (state.modes.includes('horar')) return;

      if (state.modes.includes('soul')) {
        if (value.id > ObjectType.Pluto) return;
        return setState(state => ({
          ...state,
          descriptionSoul: value.id
        }));
      }

      api.description(value.category, value.id).then(description => {
        setState(state => ({
          ...state,
          description
        }));
      });
    } else if (key === 'prompt') {
      if(!acccesOnlyForRu()) return;
      setState(state => ({
        ...state,
        prompt: value
      }));
    } else if (key === 'rectification') {
      setState(state => ({
        ...state,
        form: {
          ...state.form!,
          gen: {
            ...state.form!.gen,
            [key]: value.done
          },
          rectification: value.data,
        }
      }));
    } else if (['devplan', 'autoprediction'].includes(key)) {
      onChangedGen();
    } 
  }, [state]);

  const onModeChanged = React.useCallback((m: CircleMode | CircleMode[], synModes?: CircleMode[], progModes?: CircleMode[], relModes?: CircleMode[], modesOrder?: CircleMode[]) => {
    const _setModes = async (modes: CircleMode[]) => {
      if (!state.form) return;
      window.clearTimeout(krutilker);

      const synastryModes = synModes || state.synastryModes;
      const prognosticsModes = progModes || state.prognosticsModes;
      const relocationModes = relModes || state.relocationModes;

      const order: { [key: string]: number } = (modesOrder || [...synastryModes, ...prognosticsModes, ...relocationModes])
        .reduce((acc, curr, idx) => {
          acc[curr] = idx;
          return acc;
        }, {} as { [key: string]: number });

      modes = modes
        .map(m => ({ m, o: order[m] }))
        .sort((a, b) => a.o - b.o)
        .map(i => i.m);

      let currentMode = getMode(state.currentMode, modes);

      if (state.form.cosmogram && !modes.includes('natal')) {
        if (isPrognostics(modes)) {
          currentMode = 'transits';
          modes = [currentMode];
        } else {
          currentMode = 'natal';
          modes = [currentMode];
        }
      }

      const calc = await calculate(state.form, modes);
      goToMap(formId, modes);

      // FIXME:
      // @ts-ignore
      setState(state => ({
        ...state,
        ...calc,
        modes,
        form: {
          ...state.form,
          settings: {...state.form?.settings, dfltModes: modes}
        },
        synastryModes,
        prognosticsModes,
        relocationModes,
        currentMode,
        widgetMode: currentMode,
        pinnedAspects: [],
        highlights: []
      }));
    };

    if (typeof m === 'string') {
      if (state.modes.includes(m)) {
        if (state.modes.length > 1) {
          _setModes(state.modes.filter(i => i !== m));
        }
      } else {
        _setModes([...state.modes, m]);
      }
    } else {
      _setModes(m);
    }

  }, [state]);

  const onDateTimeChanged = React.useCallback(async (type: TimeControlType, dt: string, clearGen?: boolean) => {
    hideMapInfoPopup();

    if (!state.form) { return }

    setModeDiteTime(dt);

    const getKey = (): CircleMode => {
      if (type === 'base') {
        if (state.modes.includes('horar')) {
          return 'horar'
        }

        if (isSynastry(state.modes)) {
          if (isPartner(state.currentMode)) {
            // FIXME: fix types in CircleMode
            //@ts-ignore
            return 'partners'
          }

          if (isSynNatal(state.currentMode)) {
            return 'natal'
          }
        }
      } else {
          if (isSynastry(state.modes)) {
            if (isPartner(state.currentMode)) {
              // FIXME: fix types in CircleMode
              //@ts-ignore
              return 'partners'
            }

            if (isCompatibility(state.currentMode)) {
              return 'natal'
            }
          } else {
              // FIXME: fix types in CircleMode
              // @ts-ignore
              return 'prognostics';
          }
      }

      return 'natal'
    }

    const getPlace = () => {
      if (isSynastry(state.modes)) {
        if (isPartner(state.currentMode)) {
          const index = +(state.currentMode.slice(-1)) - 1
          return state.form?.partners && state.form.partners[index].place
        } else {
            return state.form?.natal && state.form.natal.place
        }
      }

      // FIXME: fix types
      // @ts-ignore
      return state.form[key as keyof IFormData]?.place
    }

    const updateSynastryForm = (form: IFormData, dt: string, gmt: number) => {
      let _form = form

      const updateFormNatal = () => {
        // FIXME: fix types
          //@ts-ignore
          _form.natal = {
            ..._form.natal,
            dt,
            gmt,
          }
      }

      const updateFormPartners = () => {
        const index = +(state.currentMode.slice(-1)) - 1
        const partners: ISynastryPartnerData[] = _form?.partners
          ? _form.partners.map((partner, i) => {
              if (i === index) {
                return {
                  ...partner,
                  dt,
                  gmt,
                }
              }

              return partner
            })
          : []

        _form.partners = [...partners]
      }

      if (isPartner(state.currentMode)) {
        updateFormPartners()
      }

      if (isCompatibility(state.currentMode)) {
        if (type === 'base') {
          if (store.instruments.mapIndicatorCurrent.compatibilityReversed) {
            updateFormNatal()
          } else {
            updateFormPartners()
          }

        } else {
          if (store.instruments.mapIndicatorCurrent.compatibilityReversed) {
            updateFormPartners()
          } else {
            updateFormNatal()
          }
        }
      }

      if (isSynNatal(state.currentMode)) {
        updateFormNatal()
      }

      return _form
    }

    const getForm = (dt: string, gmt: number, key: CircleMode): IFormData => {
      let form = {...state.form}

      if (isSynastry(state.modes)) {
        // FIXME: fix types
        //@ts-ignore
        form = updateSynastryForm(form, dt, gmt)

        // FIXME: fix types
        //@ts-ignore
        return form;
      }

      // FIXME: fix types
      //@ts-ignore
      form[key as keyof IFormData] = {
        // FIXME: fix types
        //@ts-ignore
        ...form[key as keyof IFormData],
        dt,
        gmt,
      }

      if (
        key === 'natal' &&
        form.relocation &&
        Object.keys(form.relocation).includes('relocation1')
      ) {
        Object.values(form.relocation ?? {}).forEach(r => {
          r.dt = dayjs(dt).add(r.gmt! - _gmt, 'hours').toISOString();
        });
      }

      if (clearGen) {
        form.gen = {
          devplan: false,
          autoprediction: false,
          rectification: false
        }
      }

      // FIXME: fix types
      //@ts-ignore
      return form;
    }

    const key: CircleMode = getKey()
    const { lat, lon } = getPlace()
    const { gmt: fixedGmt, key: dtKey } = getFixedGmt({ dt, lat, lon, data: store.settings.user.profile?.fixedGmt});
    const _gmt = fixedGmt === 0 ? fixedGmt : (fixedGmt || getGMT(dt, lat, lon));
    const form = getForm(dt, _gmt, key);


    const calc = await calculate(form, state.modes);

    onChanged('pinned-aspects', []);

    // FIXME: fix types
    // @ts-ignore
    setState(state => ({
      ...state,
      ...calc,
      skipUpdate: true
    }));

    window.clearTimeout(krutilker);
    krutilker = window.setTimeout(() => setForm({
      ...form,
      modified: nowISOString()
    }), 1000);


  }, [state]);

  const onReplace = React.useCallback((from: string, to: string) => {
    if (from === to) { return }

    const widgetsIDS = state.widgets.map(w => w.id);
    const fromIsWidget = widgetsIDS.includes(from);
    const toIsWidget = widgetsIDS.includes(to);

    if (fromIsWidget && toIsWidget) {
      const widgets = [...state.widgets];

      const fromIndex = widgets.findIndex(w => w.id === from);
      const toIndex = widgets.findIndex(w => w.id === to);

      const t = widgets[fromIndex].id;
      widgets[fromIndex].id = widgets[toIndex].id;
      widgets[toIndex].id = t;

      setState(state => ({
        ...state,
        widgets
      }));

      return;
    }

    if (fromIsWidget || toIsWidget) { return }

    const modes: CircleMode[] = [...auxModes];

    const fromIndex = modes.indexOf(from as CircleMode);
    const toIndex = modes.indexOf(to as CircleMode);

    const t = modes[fromIndex];
    modes[fromIndex] = modes[toIndex];
    modes[toIndex] = t;

    onModeChanged(
      state.modes,
      isSynastry(modes) ? modes : state.synastryModes,
      isPrognostics(modes) ? modes : state.prognosticsModes,
      isRelocation(modes) ? modes : state.relocationModes,
      modes
    );
  }, [state, auxModes]);

  const widgets = React.useMemo(() => {
    if (state.description) {
      return {
        widgets: [
          {
            order: 0,
            id: 'description',
            isActive: true
          }
        ],
        active: [{id: 'description' as WidgetTypes}]
      };
    }

    if (state.descriptionSoul !== null) {
      state.widgets.forEach(widget => {
        if (widget.id === 'interpretation-soul') widget.isActive = true;
      });
    }

    if (state.prompt && (
      state.modes.includes('natal') ||
      state.modes.includes('syn_natal') ||
      state.modes.some(isPartner)
    )) {
      return {
        widgets: [
          {
            order: 0,
            id: 'prompt',
            isActive: true
          }
        ],
        active: [{id: 'prompt' as WidgetTypes}]
      };
    }

    const searchParams = new URLSearchParams(location.search);
    const formId = +(searchParams.get('id') || -1);
    const canUsePro = state.form?.access.permissions.includes('PRO');

    const widgets = [...state.widgets]
      .sort((a, b) => a.order - b.order)
      .filter(w =>
        w.id === 'interpretation' && (
          state.modes.includes('natal') ||
          state.modes.includes('prog_natal')
          || state.modes.includes('prog_prog')
        ) ||
        w.id === 'horar-events' && state.modes.includes('horar') && !isLimitedAccess ||
        w.id === 'horar-speed' && state.modes.includes('horar') && !isLimitedAccess ||
        w.id === 'horar-essentials' && state.modes.includes('horar') && !isLimitedAccess ||
        w.id === 'formula-strength' && state.modes.includes('soul') && !isLimitedAccess ||
        w.id === 'ephemeris-formulas' && state.modes.includes('soul') && !isLimitedAccess ||
        w.id === 'horar-analysis' && state.modes.includes('horar') && !isLimitedAccess ||
        w.id === 'horar-light' && state.modes.includes('horar') && !isLimitedAccess ||
        w.id === 'formula-years' && state.modes.includes('soul') && !isLimitedAccess ||
        w.id === 'formula-soul' && state.modes.includes('soul') && !isLimitedAccess ||
        w.id === 'interpretation-soul' && state.modes.includes('soul') && !isLimitedAccess ||
        w.id === 'rectification' && state.modes.includes('natal') && (!isLimitedAccess && !isBaseTariff && !isTrialAccess) ||

        [
          'house-formulas',
          'analyze',
          'autoprediction',
          'devplan',
          'dispositors',
          'devplan',
          'autoprediction'
        ].includes(w.id) && state.modes.includes('natal') && !state.form?.cosmogram ||

        [
          'house-formulas',
          'analyze',
          'dispositors',
        ].includes(w.id) && state.modes.includes('syn_natal') ||

        [
          'aspects-table',
          'coordinates-of-planets-and-houses',
          'orbise-corrector',
          'house-formulas',
          'analyze',
          'dispositors',
        ].includes(w.id) && !state.modes.includes('horar') && !state.modes.includes('soul') ||

        w.id === 'strong-objects' && (!state.modes.includes('horar') && !state.modes.includes('soul') ) && !state.form?.cosmogram
      )
      .filter(w => !((w.id === 'devplan' || w.id === 'autoprediction') && i18n.language !== 'ru'))
      .filter(w => !((w.id === 'devplan' || w.id === 'autoprediction' || w.id === 'rectification') && (formId === -1 || store.settings.user.auth.id === -1)))
      .filter(w => w.id !== 'rectification' || !state.form?.cosmogram)
      .filter(w => {
        if (acccesOnlyForRu()) {
          return w
        } else {
          return w.id !== 'interpretation' && w.id !== 'interpretation-soul'
        }
      })
      .map(w => ({
        ...w,
        showWithAccessLimit: usedWithAccessLimitWidgets.includes(w.id)
      }));

    const _widgets = state.form?.cosmogram && modes.includes('transits') ?
      widgets.filter(w => !['strong-objects', 'house-formulas'].includes(w.id)) :
      state.form?.cosmogram && modes.includes('natal') ?
        widgets.filter(w => !['house-formulas'].includes(w.id)) :
        [...widgets];

    return {
      widgets: _widgets,
      active: _widgets
      .filter(w => w.isActive)
      .map(w => ({
        id: w.id as WidgetTypes,
        isActive: w.isActive,
      }))
    };
  }, [state, i18n.language]);

  const calcFixedStars = async (stars: string[], key: string): Promise<IFixedStarExt[]> => {
    const result: IFixedStarExt[] = [];
    for (const star of stars) {
      let tmpStar: IFixedStarExt;
      const allStarsData = StarsData.find((dataStar) => dataStar.astroName === star);
      let starData: any = key === 'horar'
        ? (HorarStarsData.find((hStar) => hStar.name === star))
        : allStarsData;

      const dt = isSynastry(state.currentMode)
        ? (state.form as any)['natal'].dt
        : (state.form as any)[key].dt

      const { lon } = await astro.star(dt, star);
      const sign = getSign(lon);

      tmpStar = {
        lon,
        ru: (allStarsData?.nameRu || starData?.title),
        en: (allStarsData?.nameEn || starData?.name),
        degree: degToString(lon),
        signRu: t.t(signs[sign].ru),
        sign: t.t(signs[sign].en),
        constellation: allStarsData?.constellation || '',
        constellationRu: allStarsData?.constellationRu || '',
        description: starData?.description || allStarsData?.description || ''
      }
      result.push(tmpStar);
    }
    return result;
  }

  const modeFixedStars = useAsyncMemo(async () => {
    let result: IFixedStarExt[] = [];

    if (!store.activeAstroProfile?.fixedStars) {
      return {};
    }

    let key: (keyof IFormData) | string = 'not-support';

    if (state.currentMode.includes('horar')) { key = 'horar' }
    else if (isSynastry(state.currentMode) && state.currentMode !== 'syn_natal') { key = 'synastry' }
    else if (['natal', 'syn_natal'].includes(state.currentMode)) { key = 'natal' }

    // @ts-ignore
    const modeStars = [...(store.activeAstroProfile?.fixedStars || {})[key]?.list || []] || [];

    // @ts-ignore
    const showWithObjects = (store.activeAstroProfile?.fixedStars || {})[key]?.showWithObjects || false;

    if (!StarsData.length || !state.form || !modeStars.length) return {};

    result = await calcFixedStars(modeStars, key);

    return {
      fixedStars: result,
      showFixedStars: showWithObjects
    };

  }, [state.form, state.currentMode]);

  const natalFixedStars = useAsyncMemo(async () => {
    // @ts-ignore
    const natalStars = [...(store.activeAstroProfile?.fixedStars || {})['natal']?.list || []];
    // @ts-ignore
    const showWithObjects = (store.activeAstroProfile?.fixedStars || {})['natal']?.showWithObjects || false;
    if (!StarsData.length || !state.form || !natalStars.length) return {};

    return {
      fixedStars: await calcFixedStars(natalStars, 'natal'),
      showFixedStars: showWithObjects
    }

  }, [state.form]);

  const widgetsData = React.useMemo(() => {
    return {
      form: state.form!,
      maps: state.maps.map((map) => ({ ...map, ...modeFixedStars })),
      modes: state.modes,
      natalMap: { ...(state.natalMap!), ...natalFixedStars },
      partnersMaps: state.partnersMaps!,
      widgetMode: state.widgetMode,
      highlights: state.highlights,
      description: state.description,
      descriptionSoul: state.descriptionSoul,
      prompt: state.prompt,
      pinnedAspects: state.pinnedAspects,
      doubleMapNatal: store.instruments.mapIndicatorCurrent.prognosticsNatal,
      strongs: state.strongs,
    }
  }, [state, modeFixedStars]);


  const onHold = React.useCallback(async (id: any, v: boolean) => {
    if (!state.form) { return }

    if (id == 'natal' && !state.modes.includes('natal')) {
      if (v) {
        calculation('natal', state.form, undefined, store.activeAstroProfile)
          .then(calc => {
            // FIXME:
            // @ts-ignore
            setState(state => ({
              ...state,
              natal: {
                mode: 'natal',
                ...calc
              }
            }));
          });
      } else {
        setState(state => ({
          ...state,
          natal: null
        }));
      }
    }
  }, [state, store.activeAstroProfile]);

  const countMapScale = (ref: React.RefObject<HTMLDivElement>) => {
    const cont = ref.current;
    const rect = cont?.getBoundingClientRect();

    if (rect?.width && rect?.height) {
      const minSide = Math.min(rect.width, rect.height);
      setMinSide(minSide);
    }
  };

  const getCalculatedMap = async (mode: CircleMode, form: IFormData, mapIndex?: number) => {
    const data = await calculation(mode, form, mapIndex, store.activeAstroProfile);
    return {
      mode,
      ...data
    };
  };

  const calculate = throttle(async (form: IFormData, modes: CircleMode[]) => {
    let natalIndex = -1;
    const natalMode: CircleMode = 'natal';
    const partnersMaps: {[key: string]: any} = {};

    const _modes = modes.filter(m => {
        return isPartner(m) || isCompatibility(m)
          ? !!form?.partners?.[(getModeNumber(m) || 0) - 1]
          : true
        }
      )

    if (_modes.length === 0) {
      _modes.push(natalMode)
    }

    const maps = await Promise.all(_modes
      .map(async (m, index) => {
        const map = await getCalculatedMap(m, form, index);

        if (m === natalMode || m === 'syn_natal') {
          natalIndex = index;
        }

        if (isCompatibility(m)) {
          const partnerMode = compatibilityToPartner(m);
          partnersMaps[partnerMode] = await getCalculatedMap(partnerMode, form);
        }
        return map;
      })
    );

    const natalMap = natalIndex >= 0
      ? maps[natalIndex]
      : await getCalculatedMap(natalMode, form);

    const strongs = natalMap ?
      {
        planets: planetsPower(natalMap as IMap),
        houses: housesPower(natalMap),
        aspects: astro.strongAspects(natalMap)
      } : initialStrongs;

    return {
      natalMap,
      partnersMaps,
      strongs,
      maps,
      form
    };
  }, 16);

  const changedViewWidget = (widgets: IWidgetsItem[], action: boolean) => widgets.map((widget: IWidgetsItem) => {
    if (
      widget.id === 'rectification' ||
      widget.id === 'interpretation' ||
      widget.id === 'autoprediction' ||
      widget.id === 'devplan'
    ) {
      return {
        ...widget,
        isActive: false
      };
    }
    return {
      ...widget,
      isActive: action ? !widget.isActive : false
    };

  });

  const changeCosmodram = () => {
    const oldForm = cloneDeep(state.form);
    const cosmogram = !state.form?.cosmogram;

    setForm({
      ...oldForm!,
      cosmogram: cosmogram,
    });
    if (state.form?.cosmogram) {
      show({
        type: 'error',
        closable: true,
        timeout: 3000,
        text: t.t("chronos.app.cosmogramOff")
      });

    } else {
      show({
        type: 'success',
        closable: true,
        timeout: 3000,
        text: t.t("chronos.app.cosmogramOn")
      });
    }
  };

  const changeAspectsMode = () => {
    if (state.currentMode === 'horar' || state.currentMode === 'soul') return;
    let minorAspectsMode = state.minorAspectsMode + 1;
    if (minorAspectsMode > MinorAspectsModes.School) minorAspectsMode = MinorAspectsModes.On;

    show({
      type: minorAspectsMode === MinorAspectsModes.On ? 'success' : minorAspectsMode === MinorAspectsModes.Off ?  'error' : 'warning',
      closable: true,
      timeout: 3000,
      text: {
        [MinorAspectsModes.On]: t.t("chronos.app.changeAspectsMode.on"),
        [MinorAspectsModes.Off]: t.t("chronos.app.changeAspectsMode.off"),
        [MinorAspectsModes.School]: t.t("chronos.app.changeAspectsMode.schoolSettings")
      }[minorAspectsMode]
    });

    setState(state => ({
      ...state,
      minorAspectsMode: minorAspectsMode
    }));
  }

  const setForm = async (form: IFormData, activeMode?: CircleMode) => {
    let { modes, relocationModes, synastryModes } = state;
    let _synastryModes = [...synastryModes];
    let _relocationModes = [...relocationModes];
    let _modes = [...modes];
    let aux: any = {};

    let switchTo = '';

    const existingPartner = state.form?.partners?.length || 0;
    const newPartner = form.partners?.length || 0;

    const switchOnPrognostics = !state.form?.prognostics && form.prognostics;
    const switchOnSynastry = (!state.form?.synastry && form.synastry) || (!state.form?.partners?.length && form.partners?.length);
    const switchOnHorar = !state.form?.horar && form.horar;
    const switchOnSoul = !state.form?.soul && form.soul;
    const switchOnRelocation = !state.form?.relocation && form.relocation;

    if (newPartner > existingPartner) {
      const newPartnerIndex = newPartner - 1;
      switchTo = `partner${newPartnerIndex + 1}`;
    }
    if (switchOnPrognostics) switchTo = 'directions';
    if (switchOnSynastry) switchTo = 'partner1';
    if (switchOnHorar) switchTo = 'horar';
    if (switchOnSoul) switchTo = 'soul';
    if (switchOnRelocation) switchTo = 'relocation_natal';

    const synastryChanged = isSynastry(_modes) ||
      (state.form?.partners?.length !== form.partners?.length) ||
      (form.partners?.length && form.settings?.modes.synastry.length === 0)
      if (synastryChanged) {
        _synastryModes = getSynastryModes(form);
        if (!_synastryModes.every(mode => _modes.includes(mode)) && !switchTo) {
          switchTo = 'partner1'; 
        }

      if (form.settings) {
        form.settings.modes.synastry = _synastryModes
      }
    }

    if (isRelocation(_modes)) {
      if (relocationModes.length !== form.settings!.modes.relocation.length) {
        switchTo = last(form.settings!.modes.relocation)!;
      }
      _relocationModes = [...form.settings!.modes.relocation];
    }

    if (activeMode || switchTo || form.cosmogram && !state.modes.includes('natal') && !state.modes.includes('transits')) {
      if (activeMode) switchTo = activeMode

      if (!switchTo) {
        if (isPrognostics(state.modes)) {
          switchTo = 'transits'
        } else {
          switchTo = 'natal'
        }
      };

      _modes = [switchTo as CircleMode];

      aux = {
        currentMode: switchTo,
        widgetMode: switchTo,
        pinnedAspects: [],
        highlights: []
      };
    }

    if(isEqual(_modes, state.modes)) {
      _modes = state.modes;
    }

		updateAuxModes(form)

    const calc = await calculate(form, _modes);

    goToMap(formId, _modes);

    const updateGenForm = {
      ...form,
      gen: {
        ...form.gen,
      },
      relocation: cloneDeep(form.relocation)
    };

    setState(state => ({
      ...state,
      ...calc,
      ...aux,
      form: updateGenForm,
      synastryModes: _synastryModes,
      relocationModes: _relocationModes,
      modes: _modes
    }));

  };

  const getFormData = async (id = formId) => {
    api.form(id)
      .then(async (form) => {
        if (!form || form.error) {
          setState(state => ({
            ...state,
            accessError: form?.error ?? ''
          }));
        } else {

          const invalidDTBlock =
            !isValidISOString(form.natal.dt) && "astro.natal" ||
            !form.partners?.length && form.synastry && !isValidISOString(form.synastry.dt) && "astro.synastry" ||
            form.partners?.length && form.partners.some(partner => !isValidISOString(partner.dt)) && "astro.partners" ||
            form.prognostics && !isValidISOString(form.prognostics.dt) && "astro.prognostics" ||
            form.horar && !isValidISOString(form.horar.dt) && "astro.horar";
            // form.rectification && !isValidISOString(form.relocation.dt)
            // FIXME: form.relocation

          if (invalidDTBlock) {
            const name = form.name
            show({
              type: 'error',
              closable: false,
              key: 'invalid_date',
              text: t.t("chronos.app.instruments.invalidDate", {name, tBlock: invalidDTBlock})
            });
            //`Произошла ошибка! В карточке '${form.name}' присутствуют некорректные данные в блоке '${invalidDTBlock}'. Проверьте правильность ввода даты в настройках карточки.`
            // t("chronos.app.instruments.invalidDate", {form.name, invalidDTBlock})
            return;
          }

          let _modes = (modes.length ? modes : form.settings?.dfltModes) ?? [];

          if (
            _modes.length === 0 ||
            isPrognostics(_modes) && !form.prognostics ||
            isSynastry(_modes) && !form.synastry && !form.partners?.length ||
            _modes.includes('horar') && !form.horar ||
            form.settings && form.settings.dfltModes.some((mode: CircleMode) => !CircleModes.includes(mode)) ||
            isLimitedAccess
          ) {
            form.settings!.dfltModes = ['natal'];
            await throttleUpdateForm(form);
            _modes = ['natal'];
          }

          if (form.settings && form.settings?.modes && !form.settings?.modes.relocation) {
            form.settings.modes.relocation = ['relocation_natal']
          }


          if (form.settings && form.settings?.modes) {
            form.settings.modes.synastry = getSynastryModes(form)
          }


          // заполняем fixedGmt
          const profileFixedGmt = { ...store.settings.user.profile?.fixedGmt };

          // проверяем и устанавливаем fixedGmt для всех типов карт
          (['natal', 'synastry', 'prognostics', 'horar'] as CircleMode[]).forEach((mode: CircleMode) => {
            if (!(form as any)[mode]) return;

            const { dt, place } = ((form as any)[mode] as ISynastryPartnerData);
            const { lat, lon } = place;
            if(dt && (lat !== undefined && lon != undefined)) {
              const { gmt: modeFixedGmt } = getFixedGmt({ dt, lat, lon, data: profileFixedGmt });
              ((form as any)[mode] as ISynastryPartnerData).gmt = modeFixedGmt ?? getGMT(dt, lat, lon);
            }

            if ((form as any)[mode].autoGmt === undefined) {
              (form as any)[mode].autoGmt = true;
            }
          });

          // проверяем и устанавливаем fixedGmt для партнеров
          (form as any)['partners']?.forEach((partner: ISynastryPartnerData, idx: number) => {
            const { dt, place } = partner;
            const { lat, lon } = place;
            if (dt && (lat !== undefined && lon != undefined)) {
              const { gmt: modeFixedGmt } = getFixedGmt({ dt, lat, lon, data: profileFixedGmt });
              ((form as any)['partners'][idx]).gmt = modeFixedGmt ?? getGMT(dt, lat, lon);
            }
          });

          if (isLimitedAccess && !isShareForm) {
            // проверяем наличие купленных построений, что бы использовать их при отсутствии подписки
            // @ts-ignore
            const { statistics: { counter, ap, ipr, rectifications } } = await store.settings.payments.getPaymentInfo(true);
            let widgetList: string[] = [];

            if (counter > 0) {
              widgetList = ['rectification', 'devplan', 'autoprediction'];
              
            } else {

              if (rectifications > 0) {
                widgetList.push('rectification');
              }
              if (ap > 0) {
                widgetList.push('autoprediction');
              }
              if (ipr > 0) {
                widgetList.push('devplan');
              }

            }

            setUsedWithAccessLimitWidgets((state) => (
              [...state, ...widgetList]
            ))
          }

          calculate(form, _modes)
            ?.then(async calc => {
              const formData = await checkActualFormData(form);
              const widgets = formData.settings?.widgets || state.widgets;
              let currentMode = getMode(state.currentMode, _modes);

              if (form.cosmogram && !_modes.includes('natal')) {
                if (isPrognostics(_modes)) {
                  currentMode = 'transits';
                  _modes = [currentMode];
                } else {
                  currentMode = 'natal';
                  _modes = [currentMode];
                }

                goToMap(id, _modes);
              }

              const synastryModes = getSynastryModes(formData)

              // FIXME:
              // @ts-ignore
              setState({
                ...state,
                ...calc,
                accessError: '',
                widgets,
                prognosticsModes: formData.settings?.modes?.prognostics?.filter(m => m !== 'prog_prog') || state.prognosticsModes, // FIXME: ?
                synastryModes,
                relocationModes: formData.settings?.modes?.relocation || state.relocationModes,
                modes: _modes,
                currentMode,
                widgetMode: currentMode
              });
            });
        }
      })
      .catch(error => {
        console.log('getFormData error -', error)
        setShowNotFound(true)
      });
  };

  const checkActualFormData = async (formData: IFormData): Promise<IFormData> => {
    let isNeedUpdate = false;

    initialState.widgets.map(widget => {
      if (formData.settings && formData.settings.widgets && !formData.settings.widgets.some((w: IWidgetsItem) => w.id === widget.id)) {
        isNeedUpdate = true;
        formData.settings.widgets.push(widget);
      }
    });

    if (isNeedUpdate && formData.access.isPersonal) {
      await throttleUpdateForm(formData);
    }

    return formData;
  };

  const getFormDataHandler = (formData: IFormData) => {
    if (formData.isTemporary) {
      calculate(formData, ['natal'])!.then(calc => {
        const newState = {
          ...state,
          ...calc,
          form: {
            ...formData,
            ...state.form, // FIXME: ?
            natal: calc.form.natal
          }
        };

        // FIXME:
        // @ts-ignore
        setState(newState);
      });
    } else {
      getFormData(formData.id);
    }
  };

  const changePrintSettings = () => {
    setScale(1);

    _oldPageTitle = document.title;
    const name = document.querySelector('.name-for-print')?.textContent;
    const place = document.querySelector('.place-for-print')?.textContent;
    document.body.setAttribute('title', `CHRONOS - ${name}, ${place}`);

    _oldPageTheme = theme.current;
    theme.current = 'light';
  }

  const restorePrintSettings = () => {
    if(_oldPageTitle) {
      document.title = _oldPageTitle;
    }
    if(_oldPageTheme) {
      theme.current = _oldPageTheme;
    }
    document.body.removeAttribute('title');
  }

  const closeWelcomeInstruments = () => {
    store.settings.user.auth.showWelcomeInstruments = false;
    showWelcomeInstruments.off();
    api.closeNotifications([
      {
        id: 'welcome_instruments'
      }
    ]);
  };

  const updateAuxModes = (form: IFormData) => {
    let auxModes: CircleMode[] = []

    if (isSynastry(state.modes)) {
      auxModes = state.synastryModes;
    }

    else if (isPrognostics(state.modes)) {
      auxModes = state.prognosticsModes;
    }

    else if (isRelocation(state.modes)) {
      auxModes = state.relocationModes;
    }

    setAuxModes(auxModes);
  }


  const finalScale = isTablet
    ? window.innerHeight >= 950 ? 0.9
    : (widgets.active.length
        ? (window.innerWidth >= 1180 ? 0.7 : 0.55)
        : 0.7
      )
    : 0.8;
  const ratio = isTablet ? 0.7 : 0.7;
  const mapSize = minSide * scale * ratio;

  if (state.modes.includes('horar') && !state.form?.access.permissions.includes('PRO') && state.form?.access) {
    return <NotFound />
  }

  if (state.accessError === 'NOT_FOUND' || state.accessError === 'PRIVATE' || showNotFound)  {
    return <NotFound />
  }

  return (
    <InstrumentsContext.Provider value={pageContext}>
      <InstrumentsContainer>
        
        {(store.settings.user.auth.id !== -1) && 
          <SideBar updateForm={getFormDataHandler} form={state.form} />
        }

        {showWelcomeInstruments.value && <WelcomeInstruments onClose={closeWelcomeInstruments} />}

        { pageContext.showTariffsPopup && <TariffsPopup onClose={() => setPageContext((ctx: IInstrumentContext) => ({ ...ctx, showTariffsPopup: false }))} /> }

        <Workspace ref={wsRef}>
          {state.accessError &&
            <CanUserService>
              <NoChartIcon />

              <div className='title'>
                {state.accessError === 'NOT_FOUND' ? t.t("chronos.app.mapNotFound") : t.t("chronos.app.accessDenied")}
              </div>

              <div className='description'>
                {
                  state.accessError === 'NOT_FOUND' ?
                    t.t("chronos.app.accessDenied.hint") :
                    state.accessError === 'PRIVATE' ?
                      t.t("chronos.app.accessDenied.userDeniedAccess") :
                      t.t("chronos.app.accessDenied.onlyAuthUsers")
                }
              </div>
                <Button
                  onClick={() => {
                    if (state.accessError === 'PROTECTED') {
                      sp.delete('share');
                      const spString = sp.toString();
                      history.replace(`${window.location.pathname}${spString ? `?${spString}` : ''}`);
                      setTimeout(() => api.goToAuth(), 16);
                    } else {
                      history.push(routes.Dashboard.path);
                    }
                  }}
            >{state.accessError === 'PROTECTED' || store.settings.user.auth.id === -1 ? t.t("base.signIn") : t.t("chronos.app.goToMapList")}</Button>
          </CanUserService>
        }
        {
            state.form && !state.accessError && widgetsData.natalMap && widgetsData.partnersMaps
            ?
              <Wrapper className="map-wrapper">
                  <Header
                      modes={state.modes}
                      form={state.form}
                      onFormEdit={setForm}
                  />

                <main className='map-print-wrapper'>
                  {isTablet
                    ? <TransformWrapper
                        key={keyForTransform}
                        initialScale={1}
                        limitToBounds={true}
                        doubleClick={{ mode: 'zoomIn' }}
                        velocityAnimation={{
                          equalToMove: true,
                          animationTime: 0,
                        }}
                      >
                      <TransformComponent>
                        <MapsSwipeContainer>
                          <MapsContainer >
                            <Maps
                              form={state.form}
                              maps={state.maps.map((map) => ({ ...map, ...modeFixedStars }))}
                              strongs={state.strongs}
                              natal={state.natal}
                              highlights={state.highlights}
                              pinnedAspects={state.pinnedAspects}
                              onChanged={onChanged}
                              currentMode={state.currentMode}
                              widgetMode={state.widgetMode}
                              isActiveWidgets={widgets.active}
                              prognosticsModes={state.prognosticsModes}
                              synastryModes={state.synastryModes}
                              relocationModes={state.relocationModes}
                              relocation={state.form.relocation}
                              onModeChanged={onModeChanged}
                              auxModes={auxModes}
                              minorAspectsMode={state.minorAspectsMode}
                              t={t}
                              countMapScale={countMapScale}
                              setScale={setScale}
                              scale={scale}
                              minSide={minSide}
                              onHold={onHold}
                              mapSize={mapSize}
                              finalScale={finalScale}
                              partners={state.form.partners}
                              limitedAccess={isLimitedAccess}
                              limitedAccessAction={() => setPageContext((ctx: IInstrumentContext) => ({ ...ctx, showTariffsPopup: true }))}
                            />
                          </MapsContainer>

                        </MapsSwipeContainer>
                      </TransformComponent>
                      </TransformWrapper>
                      : <Maps
                          form={state.form}
                          maps={state.maps.map((map) => ({...map, ...modeFixedStars}))}
                          strongs={state.strongs}
                          natal={state.natal}
                          highlights={state.highlights}
                          pinnedAspects={state.pinnedAspects}
                          onChanged={onChanged}
                          currentMode={state.currentMode}
                          widgetMode={state.widgetMode}
                          isActiveWidgets={widgets.active}
                          prognosticsModes={state.prognosticsModes}
                          synastryModes={state.synastryModes}
                          relocationModes={state.relocationModes}
                          relocation={state.form.relocation}
                          onModeChanged={onModeChanged}
                          auxModes={auxModes}
                          minorAspectsMode={state.minorAspectsMode}
                          t={t}
                          countMapScale={countMapScale}
                          setScale={setScale}
                          scale={scale}
                          minSide={minSide}
                          onHold={onHold}
                          mapSize={mapSize}
                          finalScale={finalScale}
                          partners={state.form.partners}
                          limitedAccess={isLimitedAccess}
                          limitedAccessAction={() => setPageContext((ctx: IInstrumentContext) => ({ ...ctx, showTariffsPopup: true }))}
                        />
                  }
                  {/* @ts-ignore */}
                  <Widgets widgets={widgets.active} data={widgetsData} onClose={showWidget} onChanged={onChanged} />
                </main>

                <Footer
                  widgets={widgets.widgets}
                  showWidget={showWidget}
                  showAllWidgets={onShowAllWidgets}

                  showEdit={showEdit}
                  setShowEdit={setShowEdit}

                  modes={state.modes}
                  onModeChanged={onModeChanged}

                  form={state.form}
                  onFormEdit={setForm}
                  onDateTimeChanged={onDateTimeChanged}

                  auxModes={auxModes}
                  onReplace={onReplace}

                  onHold={onHold}
                  currentMode={state.currentMode}
                  autoPrediction={state.form.gen.autoprediction}
                  limitedAccess={isLimitedAccess}
                  limitedAccessAction={() => setPageContext((ctx: IInstrumentContext) => ({ ...ctx, showTariffsPopup: true }))}
                />
              </Wrapper>

              : (!state.accessError ? <MapPreloader mapSize={mapSize} /> : null)
          }
        </Workspace>
      </InstrumentsContainer>
    </InstrumentsContext.Provider>
  );
});

const StyledAlert = styled.div`
  position: fixed;
  left: 32vw;
  bottom: 0rem;
  max-width: 48rem;
`;

const animateShow = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  animation: ${animateShow} 0.32s var(--easeOutQuart);

  ${isTablet
    ? `
      @media (max-width: 1366px) {
        padding-left: 1rem;
        box-sizing: border-box;
        height: 100%;
      }
    `
    : `
      height: 100%;
    `
  }

  & > main {
    height: 100%;
    display: flex;
    align-items: center;

    ${isTablet
        ? css`
            & > .react-transform-wrapper {
              height: 100%;
              margin: auto;
              overflow: visible;
              max-width: 100%;
              & > .react-transform-component {
                height: 100%;
              }
            }
          `
        : ''
    }
  }
`;

const InstrumentsContainer = styled.div`
  display: flex;

  height: 100%;
  width: 100%;
`;

const Workspace = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  background-color: var(--workspace-background);

  user-select: none;
`;

const CanUserService = styled.div`
  color: var(--text-primary);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  & button {
    margin-top: 20px;
  }

  & > .title {
    font-size: 1.25em;
    margin-top: 2.5em;
    margin-bottom: 1em;
  }

  & > .description {
    color: var(--text-third);
    margin-bottom: 1em;
  }
`;

const MapsSwipeContainer = styled.div`
  width: 100%;
  height: 100%;
`

const MapsContainer = styled.div`
  height: 100%;
  width: ${isTablet ? 'auto' : '100vw'};
  /* overflow: hidden; */
`
